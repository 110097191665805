import '../styles/footer.css';
import logo from '../assets/logo.webp';
import {FaPhoneAlt, FaWhatsapp, FaEnvelope} from 'react-icons/fa';

export function Footer() {
  return (
    <footer>
      <div className="container main-footer">
        <div className="logo-cotainer">
          <img src={logo} alt="dreams travels logo" className="logo" />
          <p>
            Shine Building
            <br />
            Dream Travels
            <br />
            Harehills Road,Harehills Leeds. LS8 5HS 20 Mowbray Street,
            Rotherham, England, S65 2UL
          </p>
        </div>
        <ul className="footer-contact-links">
          <li>
            <a href="" className="footer-contact-link">
              <FaPhoneAlt /> <span>0208 144 2561</span>
            </a>
          </li>
          <li>
            <a href="" className="footer-contact-link">
              <FaWhatsapp /> <span>0208 144 2561</span>
            </a>
          </li>
          <li>
            <a href="" className="footer-contact-link">
              <FaEnvelope />
              <span>info@dreamstravels.co.uk</span>
            </a>
          </li>
        </ul>
        <ul className="footer-links">
          <li>
            <a href="">Cookie Policy</a>
          </li>
          <li>
            <a href="">Privacy Policy</a>
          </li>
          <li>
            <a href="">Terms & Conditions</a>
          </li>
          <li>
            <a href="">About Us</a>
          </li>
          <a href="">Contact Us</a>
          <li></li>
        </ul>
        <div className="footer-para">
          Prices are subject to availability of seats. Availability of seats
          within date range is very limited. Call now and check with our Travel
          Consultants.
        </div>
      </div>
      <div className="bg-primary copyright">
        <span>Copyright 2024 Dreams Travels All Rights Reserved.</span>
      </div>
      <p className="footer-note container">
        Most of the flights and flight-inclusive packages that we sell are ATOL
        and IATA protected by our suppliers. All quotations are subject to
        availability at the time of booking. When you pay you will be supplied
        with an ATOL Certificate. Please ask for it and check to ensure that
        everything you booked (flights, hotels and other services) is listed on
        it. Please see our booking conditions for further information or for
        more information about financial protection and the ATOL Certificate go
        to:&nbsp;
        <a href="https://www.caa.co.uk/ATOLCertificate">
          www.atol.org.uk/ATOLCertificate
        </a>
      </p>
    </footer>
  );
}
