import {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../styles/navbar.css';
import {FaChevronDown, FaChevronUp, FaPhoneAlt} from 'react-icons/fa';
import logo from '../assets/logo.webp';

export function NavBar() {
  const [isActive, setIsActive] = useState(false); // Controls entire navbar (for mobile view)
  const [isDropdownActive, setIsDropdownActive] = useState(false); // Controls the dropdown menu

  const navbarRef = useRef<HTMLDivElement | null>(null); // Ref for navbar

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  const toggleDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  // Close dropdown when clicking outside of it
  const handleClickOutside = (event: MouseEvent) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target as Node)
    ) {
      setIsDropdownActive(false);
      setIsActive(false); // Optionally close the navbar on click outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header>
      <div className="logo-section container">
        <div>
          <img src={logo} alt="dreams travels logo" />
        </div>
        <div className="nav-contact-details">
          <span>Call out travel experts at</span>
          <div className="nav-phone-num">
            <FaPhoneAlt />
            <a>0208 144 2561</a>
          </div>
          <span>Timing 8:00am to 11:30pm Week days</span>
        </div>
      </div>
      <div className="bg-primary">
        <nav
          ref={navbarRef}
          className="navbar custom-navbar container"
          role="navigation"
          aria-label="main navigation">
          <div className="navbar-brand">
            <a
              role="button"
              className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
              aria-label="menu"
              aria-expanded="false"
              onClick={toggleNavbar}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div className={`navbar-menu  ${isActive ? 'is-active' : ''}`}>
            <div className="navbar-start">
              <Link className="navbar-item custom-navbar-item" to="/">
                Home
              </Link>

              {/* Destinations dropdown */}
              <div
                className={`navbar-item custom-navbar-item ${
                  isDropdownActive ? 'is-active' : ''
                } `}
                onClick={toggleDropdown}>
                <div className="dropdown-toggle">
                  <span>Destinations</span>
                  {isDropdownActive ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <div
                  className={`navbar-dropdown custom-dropdown ${
                    isDropdownActive ? 'is-active' : ''
                  }`}>
                  <div className="columns custom-columns">
                    <div className="column is-one-quarter">
                      <div className="dropdown-content">
                        <p className="menu-label">India Top Destinations</p>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Amritsar
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Delhi
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Mumbai
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Goa
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Hyderabad
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Ahmedabad
                        </a>
                      </div>
                    </div>
                    <div className="column is-one-quarter">
                      <div className="dropdown-content">
                        <p className="menu-label">Africa</p>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Abuja
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Abidjan
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Lagos
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Accra
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Luanda
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Douala
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Mombasa
                        </a>
                      </div>
                    </div>
                    <div className="column is-one-quarter">
                      <div className="dropdown-content">
                        <p className="menu-label">Asia</p>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Bangkok
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Beijing
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Cebu
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Dubai
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Goa
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Jeddah
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Singapore
                        </a>
                      </div>
                    </div>
                    <div className="column is-one-quarter">
                      <div className="dropdown-content">
                        <p className="menu-label">Australia</p>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Agana
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Brisbane
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Caims
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Nelson
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Noumea
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Perth
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Sydney
                        </a>
                      </div>
                    </div>
                    <div className="column is-one-quarter">
                      <div className="dropdown-content">
                        <p className="menu-label">America</p>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          New York
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Antigua
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Boston
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Bogota
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Cali
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Kingston
                        </a>
                        <a
                          className="dropdown-item custom-dropdown-item"
                          href="#">
                          Liberia
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Link className="navbar-item custom-navbar-item" to="/about-us">
                About Us
              </Link>
              <Link className="navbar-item custom-navbar-item" to="/flight">
                Flight
              </Link>
              <Link className="navbar-item custom-navbar-item" to="/contact-us">
                Contact Us
              </Link>
              <Link
                className="navbar-item custom-navbar-item"
                to="/request-callback">
                Call Back
              </Link>
              <Link className="navbar-item custom-navbar-item" to="/register">
                Account
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
