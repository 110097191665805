import 'bulma/css/bulma.min.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {NavBar, Footer} from './components/';
import {
  Home,
  AboutUs,
  ContactUs,
  CallBack,
  Account,
  Tickets,
  Flight,
} from './pages';

function App() {
  return (
    <Router>
      <NavBar />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/flight" element={<Flight />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/request-callback" element={<CallBack />} />
          <Route path="/register" element={<Account />} />
          <Route path="/tickets" element={<Tickets />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
