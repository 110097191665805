import '../styles/clients.css';
import Marquee from 'react-fast-marquee';

import france from '../assets/clients/air-france.png';
import amarican from '../assets/clients/amarican-airlines.png';
import british from '../assets/clients/british-airways.png';
import cathy from '../assets/clients/cathay-pacific.png';
import emirates from '../assets/clients/emirates.png';
import ethioplan from '../assets/clients/ethioplan.png';
import ethad from '../assets/clients/ethad.jpg';
import kenya from '../assets/clients/kenya-airways.png';
import klm from '../assets/clients/klm.png';
import qatar from '../assets/clients/qatar-airways.png';
import southAfrica from '../assets/clients/south-africa-airways.png';
import turkish from '../assets/clients/turkish-airlines.png';
import virgin from '../assets/clients/virgin.jpg';
import virginAtlantic from '../assets/clients/virgin-atlantic.png';

export function Clients() {
  return (
    <section className="clients-section">
      <div className="container">
        <Marquee>
          <img src={france} alt="france airways logo" className="client-logo" />
          <img
            src={amarican}
            alt="amarican airlines logo"
            className="client-logo"
          />
          <img
            src={british}
            alt="british airways logo"
            className="client-logo"
          />
          <img src={cathy} alt="cathy pacific logo" className="client-logo" />
          <img src={emirates} alt="emirates logo" className="client-logo" />
          <img src={ethioplan} alt="ethioplan logo" className="client-logo" />
          <img src={ethad} alt="ethad airways logo" className="client-logo" />
          <img src={kenya} alt="kenya airways logo" className="client-logo" />
          <img src={klm} alt="klm logo" className="client-logo" />
          <img src={qatar} alt="qatar airways logo" className="client-logo" />
          <img
            src={southAfrica}
            alt="south africa airways"
            className="client-logo"
          />
          <img
            src={turkish}
            alt="tukish airlines logo"
            className="client-logo"
          />
          <img src={virgin} alt="virgin logo" className="client-logo" />
          <img
            src={virginAtlantic}
            alt="virgin atlantic logo"
            className="client-logo"
          />
        </Marquee>
      </div>
    </section>
  );
}
