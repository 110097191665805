import {useState, useReducer} from 'react';
import '../styles/header.css';
import {
  FaPlane,
  FaPhoneAlt,
  FaBed,
  FaPlaneArrival,
  FaPlaneDeparture,
} from 'react-icons/fa';
import kenya from '../assets/flags/kenya.png';

// Define types for the form state
interface Flight {
  from: string;
  to: string;
  cabinClass: string;
  departure: string;
  returnDate?: string;
  adults: number;
  children: number;
  infants: number;
}

type TripType = 'round-trip' | 'one-way' | 'multi-city';

interface FormState {
  tripType: TripType;
  flights: Flight[];
  name: string;
  email: string;
  phone: string;
}

type FormAction =
  | {type: 'SET_TRIP_TYPE'; payload: TripType}
  | {type: 'SET_FLIGHT'; index: number; payload: Partial<Flight>}
  | {type: 'ADD_FLIGHT'}
  | {type: 'SET_FIELD'; field: keyof FormState; payload: unknown};

// Initial state
const initialState: FormState = {
  tripType: 'round-trip',
  flights: [
    {
      from: '',
      to: '',
      cabinClass: 'economy',
      departure: '',
      returnDate: '',
      adults: 1,
      children: 0,
      infants: 0,
    },
  ],
  name: '',
  email: '',
  phone: '',
};

// Reducer function
const flightReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'SET_TRIP_TYPE': {
      return {...state, tripType: action.payload};
    }

    case 'SET_FLIGHT': {
      const updatedFlights: Flight[] = [...state.flights];
      updatedFlights[action.index] = {
        ...updatedFlights[action.index],
        ...action.payload,
      };
      return {...state, flights: updatedFlights};
    }

    case 'ADD_FLIGHT': {
      return {
        ...state,
        flights: [
          ...state.flights,
          {
            from: '',
            to: '',
            cabinClass: 'economy',
            departure: '',
            returnDate: '',
            adults: 1,
            children: 0,
            infants: 0,
          },
        ],
      };
    }

    case 'SET_FIELD': {
      return {...state, [action.field]: action.payload};
    }

    default:
      return state;
  }
};

export function Header() {
  const [activeTab, setActiveTab] = useState('flight');

  const handleActiveTab = function (text: string) {
    setActiveTab(text);
  };

  const [state, dispatch] = useReducer(flightReducer, initialState);

  const handleTripTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: 'SET_TRIP_TYPE', payload: e.target.id as TripType});
  };

  const handleFlightChange = (
    index: number,
    field: keyof Flight,
    value: string,
  ) => {
    dispatch({type: 'SET_FLIGHT', index, payload: {[field]: value}});
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    dispatch({
      type: 'SET_FIELD',
      field: e.target.id as keyof FormState,
      payload: e.target.value,
    });
  };

  // const addNewFlight = () => {
  //   dispatch({type: 'ADD_FLIGHT'});
  // };
  return (
    <header className="page-header">
      <div className="container">
        <section className="header-form-container">
          <div className="flight-form-btns-container">
            <button
              className={`flight-form-btn ${activeTab === 'flight' ? 'active-tab' : ''}`}
              onClick={() => handleActiveTab('flight')}
              type="button">
              <FaPlane /> <span>Flights</span>
            </button>
            <button
              className={`flight-form-btn ${activeTab === 'hotel' ? 'active-tab' : ''}`}
              onClick={() => handleActiveTab('hotel')}
              type="button">
              <FaBed />
              <span>Hotels</span>
            </button>
          </div>
          {activeTab === 'flight' ? (
            <form action="submit" className="header-form">
              <div className="trip-details">
                <input
                  type="radio"
                  id="round-trip"
                  name="flight-details"
                  checked={state.tripType === 'round-trip'}
                  onChange={e => handleTripTypeChange(e)}
                />
                <label htmlFor="round-trip">Round Trip</label>
                <input
                  type="radio"
                  id="one-way"
                  name="flight-details"
                  checked={state.tripType === 'one-way'}
                  onChange={e => handleTripTypeChange(e)}
                />
                <label htmlFor="one-way">One Way</label>
                <input
                  type="radio"
                  id="multi-city"
                  name="flight-details"
                  checked={state.tripType === 'multi-city'}
                  onChange={e => handleTripTypeChange(e)}
                />
                <label htmlFor="multi-city">Multi-city</label>
              </div>

              {state.flights.map((flight, index) => (
                <div key={index}>
                  <div key={index} className="flex-column gap-2">
                    <div key={index} className="flight-route flex-row gap-2">
                      <div className="flex-column">
                        {state.tripType === 'multi-city' && <p>1st LEG</p>}
                        <label htmlFor={`from-${index}`}>From</label>
                        <div className="input-icon-container">
                          <input
                            type="text"
                            id={`from-${index}`}
                            value={flight.from}
                            onChange={e =>
                              handleFlightChange(index, 'from', e.target.value)
                            }
                          />
                          <FaPlaneDeparture className="input-icon" />
                        </div>
                      </div>
                      <div className="flex-column">
                        <label htmlFor={`to-${index}`}>To</label>
                        <div className="input-icon-container">
                          <input
                            type="text"
                            id={`to-${index}`}
                            value={flight.to}
                            onChange={e =>
                              handleFlightChange(index, 'to', e.target.value)
                            }
                          />
                          <FaPlaneArrival className="input-icon" />
                        </div>
                      </div>
                      <div className="flex-column">
                        <label htmlFor={`cabinClass}`}>Cabin Class</label>
                        <select
                          id={`cabinClass`}
                          value={flight.cabinClass}
                          onChange={e =>
                            handleFlightChange(
                              index,
                              'cabinClass',
                              e.target.value,
                            )
                          }>
                          <option value="economy">Economy</option>
                          <option value="premium-economy">
                            Premium Economy
                          </option>
                          <option value="business">Business</option>
                        </select>
                      </div>
                    </div>
                    {/*  */}
                    <div className="flight-duration flex-row gap-2">
                      <div className="flex-column">
                        <label htmlFor={`departure-${index}`}>Departure</label>
                        <input
                          type="date"
                          id={`departure-${index}`}
                          value={flight.departure}
                          onChange={e =>
                            handleFlightChange(
                              index,
                              'departure',
                              e.target.value,
                            )
                          }
                        />
                      </div>
                      {state.tripType !== 'one-way' && (
                        <div className="flex-column">
                          <label htmlFor={`return-${index}`}>Return</label>
                          <input
                            type="date"
                            id={`return-${index}`}
                            value={flight.returnDate || ''}
                            onChange={e =>
                              handleFlightChange(
                                index,
                                'returnDate',
                                e.target.value,
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="flight-persons flex-row gap-2">
                      <div className="flex-column">
                        <label htmlFor={`adults-${index}`}>Adults</label>
                        <input
                          type="number"
                          id={`adults-${index}`}
                          value={flight.adults}
                          onChange={e =>
                            handleFlightChange(index, 'adults', e.target.value)
                          }
                        />
                      </div>
                      <div className="flex-column">
                        <label htmlFor={`children-${index}`}>Children</label>
                        <input
                          type="number"
                          id={`children-${index}`}
                          value={flight.children}
                          onChange={e =>
                            handleFlightChange(
                              index,
                              'children',
                              e.target.value,
                            )
                          }
                        />
                      </div>
                      <div className="flex-column">
                        <label htmlFor={`infants-${index}`}>Infants</label>
                        <input
                          type="number"
                          id={`infants-${index}`}
                          value={flight.infants}
                          onChange={e =>
                            handleFlightChange(index, 'infants', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {state.tripType === 'multi-city' && (
                    <div key={index + 1} className="flex-column gap-2">
                      <div
                        key={index + 1}
                        className="flight-route flex-row gap-2">
                        <div className="flex-column">
                          {state.tripType === 'multi-city' && <p>2st LEG</p>}
                          <label htmlFor={`from-${index + 1}`}>From</label>
                          <div className="input-icon-container">
                            <input
                              type="text"
                              id={`from-${index + 1 + 1}`}
                              value={flight.from}
                              onChange={e =>
                                handleFlightChange(
                                  index + 1,
                                  'from',
                                  e.target.value,
                                )
                              }
                            />
                            <FaPlaneDeparture className="input-icon" />
                          </div>
                        </div>
                        <div className="flex-column">
                          <label htmlFor={`to-${index + 1}`}>To</label>
                          <div className="input-icon-container">
                            <input
                              type="text"
                              id={`to-${index + 1}`}
                              value={flight.to}
                              onChange={e =>
                                handleFlightChange(
                                  index + 1,
                                  'to',
                                  e.target.value,
                                )
                              }
                            />
                            <FaPlaneArrival className="input-icon" />
                          </div>
                        </div>
                        <div className="flex-column">
                          <label htmlFor={`cabinClass}`}>Cabin Class</label>
                          <select
                            id={`cabinClass`}
                            value={flight.cabinClass}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'cabinClass',
                                e.target.value,
                              )
                            }>
                            <option value="economy">Economy</option>
                            <option value="premium-economy">
                              Premium Economy
                            </option>
                            <option value="business">Business</option>
                          </select>
                        </div>
                      </div>
                      {/*  */}
                      <div className="flight-duration flex-row gap-2">
                        <div className="flex-column">
                          <label htmlFor={`departure-${index + 1}`}>
                            Departure
                          </label>
                          <input
                            type="date"
                            id={`departure-${index + 1}`}
                            value={flight.departure}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'departure',
                                e.target.value,
                              )
                            }
                          />
                        </div>

                        <div className="flex-column">
                          <label htmlFor={`return-${index + 1}`}>Return</label>
                          <input
                            type="date"
                            id={`return-${index + 1}`}
                            value={flight.returnDate || ''}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'returnDate',
                                e.target.value,
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="flight-persons flex-row gap-2">
                        <div className="flex-column">
                          <label htmlFor={`adults-${index + 1}`}>Adults</label>
                          <input
                            type="number"
                            id={`adults-${index + 1}`}
                            value={flight.adults}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'adults',
                                e.target.value,
                              )
                            }
                          />
                        </div>
                        <div className="flex-column">
                          <label htmlFor={`children-${index + 1}`}>
                            Children
                          </label>
                          <input
                            type="number"
                            id={`children-${index + 1}`}
                            value={flight.children}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'children',
                                e.target.value,
                              )
                            }
                          />
                        </div>
                        <div className="flex-column">
                          <label htmlFor={`infants-${index + 1}`}>
                            Infants
                          </label>
                          <input
                            type="number"
                            id={`infants-${index + 1}`}
                            value={flight.infants}
                            onChange={e =>
                              handleFlightChange(
                                index + 1,
                                'infants',
                                e.target.value,
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {/* 
              {state.tripType === 'multi-city' && (
                
              )} */}

              <div className="flight-user-details flex-row">
                <div className="flex-column">
                  <label htmlFor="name">Name*</label>
                  <input
                    type="text"
                    id="name"
                    value={state.name}
                    onChange={e => handleFieldChange(e)}
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="text"
                    id="email"
                    value={state.email}
                    onChange={e => handleFieldChange(e)}
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor="phone">Phone*</label>
                  <input
                    type="text"
                    id="phone"
                    value={state.phone}
                    onChange={e => handleFieldChange(e)}
                  />
                </div>
              </div>

              <button type="submit">Search</button>
            </form>
          ) : (
            <form action="submit" className="header-form">
              <div className="trip-details">
                <input type="radio" id="work" name="flight-details" />
                <label htmlFor="work">Work</label>
                <input type="radio" id="Vacation" name="flight-details" />
                <label htmlFor="Vacation">Vacation</label>
              </div>
              <div className="flex-row gap-2 flight-route">
                <div className="flex-column">
                  <label htmlFor="destination">Destinations*</label>
                  <input type="text" id="destination" />
                </div>
                <div className="flex-column">
                  <label htmlFor="rooms">Rooms*</label>
                  <input type="number" id="rooms" />
                </div>
                <div className="flex-column">
                  <label htmlFor="to">Star rating</label>
                  <input type="text" id="to" />
                </div>
              </div>
              <div className="flight-duration flex-row">
                <div className="flex-column">
                  <label htmlFor="departure">Departure</label>
                  <input type="date" id="departure" />
                </div>
                <div className="flex-column">
                  <label htmlFor="return">Return</label>
                  <input type="date" id="return" />
                </div>
              </div>
              <div className="flight-persons flex-row">
                <div className="flex-column">
                  <label htmlFor="adult">Adult(12+)</label>
                  <input type="text" id="adult" />
                </div>
                <div className="flex-column">
                  <label htmlFor="child">Child(2-12)</label>
                  <input type="text" id="child" />
                </div>
                <div className="flex-column">
                  <label htmlFor="infant">Infant(&lt;2)</label>
                  <input type="text" id="infant" />
                </div>
              </div>
              <div className="flight-user-details flex-row">
                <div className="flex-column">
                  <label htmlFor="name">Name*</label>
                  <input type="text" id="name" />
                </div>
                <div className="flex-column">
                  <label htmlFor="email">Email*</label>
                  <input type="text" id="email" />
                </div>
                <div className="flex-column">
                  <label htmlFor="phone">Phone*</label>
                  <input type="text" id="phone" />
                </div>
                <button type="submit">Search</button>
              </div>
            </form>
          )}
        </section>
        <TicketSection
          flag={kenya}
          countryName={'Kenya'}
          city={'Lahore'}
          price={'456'}
        />
      </div>
    </header>
  );
}

function TicketSection(_props: {
  flag: string;
  countryName: string;
  city: string;
  price: string;
}) {
  return (
    <div className="header-tickets-section">
      <div className="ticket-country-name">
        <img src={_props.flag} alt={_props.flag} />
        <p>{_props.countryName}</p>
      </div>
      <div className="header-ticket-container">
        <Ticket city={'Lahore'} price={'675'} />
        <Ticket city={'Islamabd'} price={'453'} />
        <Ticket city={'Karachi'} price={'634'} />
        <Ticket city={'Sialkot'} price={'233'} />
      </div>
    </div>
  );
}

function Ticket(_props: {city: string; price: string}) {
  return (
    <div className="header-ticket">
      <div className="ticket-city flex-row">
        <div className="ticket-city-name flex-row">
          <FaPlane />
          <p>{_props.city}</p>
        </div>
        <div className="flex-row">
          <div className="ticket-divider"></div>
          <span className="ticket-price-text">Price*</span>
          <p className="ticket-price">£{_props.price}</p>
        </div>
      </div>
      <div className="ticket-callback flex-row">
        <button className="select-ticket">select</button>
        <a className="request-ticket flex-row">
          <FaPhoneAlt />
          <p>Request a call back</p>
        </a>
      </div>
    </div>
  );
}
