import {useState} from 'react';
import {Divider, Input} from '../components';
import {apiRequest} from '../utils/apiRequests';
import '../styles/pages/call-back.css';

const initialState = {
  customer_name: '',
  email: '',
  phone: '',
  best_time: '',
  instructions: '',
};

export function CallBack() {
  const [callBack, setCallBack] = useState(initialState);
  const [loading, setLoading] = useState(false);

  function handleInputChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) {
    setCallBack({...callBack, [e.target.name]: e.target.value});
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setCallBack(initialState);

    const body = JSON.stringify({callback_request: callBack});
    await apiRequest(
      'https://dream-travels-backend-9eaa9bf82980.herokuapp.com/api/v1/callback_requests',
      body,
    );
    setLoading(false);
    alert("Thanks for Requesting. We'll get back to you as soon as possible.");
  };

  return (
    <main className="container call-back">
      <h1>Request a call back - We will call you back within 10 minutes.</h1>
      <Divider />

      <form action="submit" onSubmit={handleSubmit}>
        <Input
          label="Customer Name(*)"
          type="text"
          name="customer_name"
          isRequired={true}
          onChange={handleInputChange}
          value={callBack.customer_name}
        />
        <Input
          label="E-mail(*)"
          type="email"
          name="email"
          isRequired={true}
          onChange={handleInputChange}
          value={callBack.email}
        />
        <Input
          label="Phone/Mobile Number(*)"
          type="number"
          name="phone"
          isRequired={true}
          onChange={handleInputChange}
          value={callBack.phone}
        />
        <Input
          label="What is the best time to callback?"
          type="text"
          name="best_time"
          isRequired={true}
          onChange={handleInputChange}
          value={callBack.best_time}
        />
        <div className="message">
          <label htmlFor="message" className="label">
            Any Instructions?
          </label>
          <textarea
            name="instructions"
            id="instructions"
            required
            className="textarea"
            value={callBack.instructions}
            onChange={e => handleInputChange(e)}></textarea>
        </div>
        <button
          className={`submit-btn ${loading ? 'disabled-button' : ''}`}
          type="submit"
          disabled={loading}>
          proceed
        </button>
      </form>
    </main>
  );
}
