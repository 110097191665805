import {
  Clients,
  Services,
  Contact,
  Header,
  Flights,
  Destinations,
} from '../components';

export function Home() {
  return (
    <main>
      <Header />
      <Flights />
      <Clients />
      <Services />
      <Contact />
      <Destinations />
    </main>
  );
}
