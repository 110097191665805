import '../styles/flights.css';
import {FaArrowRight} from 'react-icons/fa';

import emirates from '../assets/clients/emirates.png';

export function Flights() {
  const flightData = [
    {
      airlineLogo: emirates,
      departureDate: '03 Nov 2024',
      returnDate: '13 Nov 2024',
      firstCity: 'Heathrow',
      secondCity: 'Accra',
      firstCityShort: 'LHR',
      secondCityShort: 'ACC',
      price: '325',
    },
    {
      airlineLogo: emirates,
      departureDate: '03 Nov 2024',
      returnDate: '13 Nov 2024',
      firstCity: 'Heathrow',
      secondCity: 'Accra',
      firstCityShort: 'LHR',
      secondCityShort: 'ACC',
      price: '325',
    },
    {
      airlineLogo: emirates,
      departureDate: '03 Nov 2024',
      returnDate: '13 Nov 2024',
      firstCity: 'Heathrow',
      secondCity: 'Accra',
      firstCityShort: 'LHR',
      secondCityShort: 'ACC',
      price: '325',
    },
    {
      airlineLogo: emirates,
      departureDate: '03 Nov 2024',
      returnDate: '13 Nov 2024',
      firstCity: 'Heathrow',
      secondCity: 'Accra',
      firstCityShort: 'LHR',
      secondCityShort: 'ACC',
      price: '325',
    },
    {
      airlineLogo: emirates,
      departureDate: '03 Nov 2024',
      returnDate: '13 Nov 2024',
      firstCity: 'Heathrow',
      secondCity: 'Accra',
      firstCityShort: 'LHR',
      secondCityShort: 'ACC',
      price: '325',
    },
    {
      airlineLogo: emirates,
      departureDate: '03 Nov 2024',
      returnDate: '13 Nov 2024',
      firstCity: 'Heathrow',
      secondCity: 'Accra',
      firstCityShort: 'LHR',
      secondCityShort: 'ACC',
      price: '325',
    },
  ];
  return (
    <section className="container">
      <div className="flight-heading-section">
        <p className="flight-return">
          Return Economy class departuring on 03 Nov 2024 and returning on 13
          Nov 2024 () for 1 Adult
        </p>
        <h2 className="flight-destinations">United Kingdom to Jeddah (JED)</h2>
        <p className="flight-return">
          Return Economy class departuring on 12-Mar-2019 and returning on
          22-Mar-2019 Heathrow (LHR) for 1 Adult
        </p>
      </div>
      <div className="all-flights-section">
        {flightData.map((data, index) => {
          return (
            <div className="flight-card flex-row" key={index}>
              <img
                src={data.airlineLogo}
                alt="flight airline logo"
                className="flight-airline-logo"
              />
              <div className="flex-column">
                <p className="flight-departure-date">
                  {data.departureDate} - {data.returnDate}
                </p>
                <div className="flex-row">
                  <div className="flex-column">
                    <h3>{data.firstCityShort}</h3>
                    <small>{data.firstCity}</small>
                  </div>
                  <FaArrowRight className="right-icon" />

                  <div className="flex-column">
                    <h3>{data.secondCityShort}</h3>
                    <small>{data.secondCity}</small>
                  </div>
                </div>
              </div>
              <div className="flex-column flight-price-section">
                <button>&pound;{data.price}</button>
                <p>Return</p>
                <p>Rtn, Incl, Taxes</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
