import {FaPlane, FaPhoneAlt} from 'react-icons/fa';
import '../styles/pages/tickets.css';

import emirates from '../assets/clients/emirates.png';

export function Tickets() {
  const ticketData = [
    {
      heading: 'TAP Air Portugal -TP',
      airline: emirates,
      firstCity: 'London Heathrow - LHR',
      firstDate: 'Tue, Nov, 5',
      secondCity: 'ACCRA - ACC',
      secondDate: 'Tue, Nov, 5',
      price: '345',
    },
    {
      heading: 'TAP Air Portugal -TP',
      airline: emirates,
      firstCity: 'London Heathrow - LHR',
      firstDate: 'Tue, Nov, 5',
      secondCity: 'ACCRA - ACC',
      secondDate: 'Tue, Nov, 5',
      price: '345',
    },
    {
      heading: 'TAP Air Portugal -TP',
      airline: emirates,
      firstCity: 'London Heathrow - LHR',
      firstDate: 'Tue, Nov, 5',
      secondCity: 'ACCRA - ACC',
      secondDate: 'Tue, Nov, 5',
      price: '345',
    },
    {
      heading: 'TAP Air Portugal -TP',
      airline: emirates,
      firstCity: 'London Heathrow - LHR',
      firstDate: 'Tue, Nov, 5',
      secondCity: 'ACCRA - ACC',
      secondDate: 'Tue, Nov, 5',
      price: '345',
    },
  ];
  return (
    <div className="tickets-page">
      <section className="container">
        <h1>London Heathrow - LHR , ACCRA - ACC</h1>
        <div className="flex-column gap-2">
          {ticketData.map((data, index) => {
            return (
              <div className="ticket" key={index}>
                <div className="ticket-heading">
                  <p>TAP Air Portugal -TP</p>
                </div>
                <div className="ticket-body">
                  <p>Outbound</p>
                  <div className="flex-row ticket-info-section">
                    <img
                      src={data.airline}
                      alt="flight airline logo"
                      className="flight-airline-logo"
                    />
                    <div className="flex-row  ticket-flight-details">
                      <div className="flex-column">
                        <FaPlane className="ticket-plane-icon take-off-icon" />
                        <p>{data.firstCity}</p>
                        <p>{data.firstDate}</p>
                      </div>
                      <div className="flight-divider"></div>
                      <div className="flex-column">
                        <FaPlane className="ticket-plane-icon land-icon" />
                        <p>{data.secondCity}</p>
                        <p>{data.secondDate}</p>
                      </div>
                    </div>
                    <div className="flex-column ticket-details">
                      <p>Ticket Price</p>
                      <p>&pound;{data.price}</p>
                      <button>Book Now</button>
                      <p>Limited seats...</p>
                      <p>Need Help To Reserve</p>
                      <a href="callto:+92081442561">
                        <FaPhoneAlt /> <span>0208 144 2561</span>
                      </a>
                      <a>
                        <FaPhoneAlt />
                        <span>Request callback</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section>
        <div className="tickets_data">
          <div className="ticket_data">
            <div className="ticket_data_head">
              <h3>Why Book with us over the phone</h3>
            </div>
            <div className="ticket_p">
              <p>
                There are some special fares available over the phone only. So
                why not pick up the phone and give us a call. We have special
                fares you will not get anywhere else.
              </p>
              <p>All Calls Are Answered on Top Priority</p>
              <p>
                Call now to check current availability of our best buy fares.
              </p>
            </div>
          </div>
          <div className="ticket_form">
            <div className="ticket_form_head">
              <h3>Enquire Now:</h3>
            </div>

            <form action="" className="ticket_form_data">
              <div className="form_data">
                <div className="form_input">
                  <label htmlFor="name">First & Last Name (*)</label>
                  <input type="text" />
                </div>
                <div className="form_input">
                  <label htmlFor="name">E-mail (*)</label>
                  <input type="text" />
                </div>
                <div className="form_input">
                  <label htmlFor="name">First & Last Name(*)</label>
                  <input type="text" />
                </div>
              </div>
              <div className="form_btn">
                <a href="#">Book now</a>
              </div>
            </form>
          </div>
        </div>

        <div className="tickets_clients">
          <h2>Your selected flight itinerary</h2>
        </div>

        <div className="clients_tickets">
          <div className="client_ticket">
            <div className="clients">
              <div className="clients_links">
                <div className="client_logo">
                  <a href="#">
                    <img src={emirates} alt="client image" />
                  </a>
                  <a href="#">
                    <p>Royal Air Maroc - AT</p>
                  </a>
                </div>
                <div className="destination_location">
                  <FaPlane className="icon" />
                  <a href="#">
                    <h5>London Heathrow</h5>
                    <p>Wed,Nov,6</p>
                  </a>
                </div>
                <div className="destination_country">
                  <a href="#">
                    <FaPlane className="icons" />
                    <h2>ACCRA</h2>
                  </a>
                </div>
              </div>
              <div className="clients_links">
                <div className="client_logo">
                  <a href="#">
                    <img src={emirates} alt="client image" />
                  </a>
                  <a href="#">
                    <p>Royal Air Maroc - AT</p>
                  </a>
                </div>
                <div className="destination_location">
                  <FaPlane className="icon" />
                  <a href="#">
                    <h5>London Heathrow</h5>
                    <p>Wed,Nov,6</p>
                  </a>
                </div>
                <div className="destination_country">
                  <a href="#">
                    <FaPlane className="icons" />
                    <h2>ACCRA</h2>
                  </a>
                </div>
              </div>
            </div>
            <div className="ticket_price">
              <div className="price_box">
                <a href="#">
                  <p className="price">&pound;490</p>
                  <p>Per Person</p>
                  <p>Call Now:</p>
                  <a href="tel:02081442561" className="a">
                    02081442561
                  </a>
                  <p>Extremely Limited Availablity</p>
                  <div className="form_btn_top">
                    <a href="#">Call Now</a>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
