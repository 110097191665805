import '../styles/services.css';
import {FaUsers, FaHeadset, FaPoundSign, FaPlane} from 'react-icons/fa';

export function Services() {
  return (
    <section className="service-section">
      <div className="service-container">
        <div className="service-card">
          <div className="service-icon-bg">
            <FaUsers className="service-icon" />
          </div>
          <div className="service-details">
            <h2 className="service-heading">Happy Travellers</h2>
            <p className="service-para">
              We offer flights to worldwide destionations from all major
              airports of UK on all major airlines. We work with low-cost
              carriers to main worldwide airlines to make our customers feel
              more happier.
            </p>
          </div>
        </div>
        <div className="service-card">
          <div className="service-icon-bg">
            <FaHeadset className="service-icon" />
          </div>
          <div className="service-details">
            <h2 className="service-heading">UK&apos;s Leading Travel Brand</h2>
            <p className="service-para">
              If you need any help to book your trip and explore the cheap
              travel option then confidently call our friendly, knowledgable
              travel specialists to assist you with your travel plans. 0208 144
              2561
            </p>
          </div>
        </div>
        <div className="service-card">
          <div className="service-icon-bg">
            <FaPoundSign className="service-icon" />
          </div>
          <div className="service-details">
            <h2 className="service-heading">Best Price Guarantee</h2>
            <p className="service-para">
              We have a travel deposit scheme to help you spread the cost and
              make your travel affordable. Confirm your travel by just paying
              small deposit of £50 and pay the rest later on desired or defined
              time intervals.
            </p>
          </div>
        </div>
        <div className="service-card">
          <div className="service-icon-bg">
            <FaPlane className="service-icon" />
          </div>
          <div className="service-details">
            <h2 className="service-heading">Most Trusted Online Travel</h2>
            <p className="service-para">
              More than 7 million people join us each month and use our services
              to find and book airline tickets.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
