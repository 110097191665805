import {Services, Contact, Header, Destinations} from '../components';

export function Flight() {
  return (
    <main>
      <Header />
      <Services />
      <Contact />
      <Destinations />
    </main>
  );
}
